import { Link } from "gatsby"
import { Column } from "./Column"
import { Row } from "./Row"
import { NavBar } from "./NavBar"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import SmoothCollapse from "react-smooth-collapse"
import React, { useState } from "react"
import styled from "styled-components"
import "../utils/fontawesome"
import "../styles/global.css"

const HeaderWrapper = styled.header`
  background-color: #545fec;
`

const HeaderContainer = styled.div`
  margin: 0 auto;
  max-width: 960px;
  padding-left: 20px;
  padding-right: 20px;
`

const HeaderFlex = styled.div`
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  display: flex;
`

export default () => {
  const [menuIsOpen, setMenuOpen] = useState(false)
  const [darkModeIsOn, setDarkModeIsOn] = useState(false)

  return (
    <HeaderWrapper>
      <HeaderContainer>
        <Row breakpoints={[280]}>
          <Column widths={[12]}>
            <HeaderFlex>
              <Link to="/"></Link>
              <button
                type="button"
                aria-label="dark mode"
                style={{ fontSize: 22, margin: 0, display: "none" }}
                onClick={() => setDarkModeIsOn(!darkModeIsOn)}
              >
                {!darkModeIsOn ? (
                  <FontAwesomeIcon icon={"moon"} />
                ) : (
                  <FontAwesomeIcon icon={"sun"} />
                )}
              </button>
              <button
                type="button"
                aria-label="menu"
                style={{ fontSize: 22, marginLeft: `20px`, color: `#fff` }}
                onClick={() => setMenuOpen(!menuIsOpen)}
              >
                {!menuIsOpen ? (
                  <FontAwesomeIcon icon={"bars"} />
                ) : (
                  <FontAwesomeIcon icon={"times"} />
                )}
              </button>
            </HeaderFlex>
          </Column>
        </Row>
        <Row breakpoints={[280]}>
          <Column widths={[12]} padding={0}>
            <div className="text-center">
              <SmoothCollapse expanded={menuIsOpen} className="">
                <NavBar />
              </SmoothCollapse>
            </div>
          </Column>
        </Row>
      </HeaderContainer>
    </HeaderWrapper>
  )
}
