import React from "react"
import { Link } from "gatsby"
import Emoji from "react-emoji-render"
import { Column } from "./Column"
import { Row } from "./Row"
import styled from "styled-components"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const NavBarText = styled.h3`
  position: relative;
  text-decoration: none;
  display: inline-block;
  color: hsla(0, 0%, 0%, 0.8);
  font-size: 1.17em;
  margin-top: 0;
  margin-bottom: 0;
  color: #fff;
`

export const NavBar = () => {
  return (
    <Row breakpoints={[580]}>
      <Column widths={[3]}>
        <div>
          <Link to="/#">
            <NavBarText>
              <Emoji text=":house:" /> Home
            </NavBarText>
          </Link>
        </div>
      </Column>
      <Column widths={[3]}>
        <div className="about-section">
          <Link to="/#foot">
            <NavBarText>
              <Emoji text=":wave:" /> About me
            </NavBarText>
          </Link>
        </div>
      </Column>
      <Column widths={[3]}>
        <div>
          <Link to="/projects">
            <NavBarText>
              <Emoji text="📂" /> Projects
            </NavBarText>
          </Link>
        </div>
      </Column>
    </Row>
  )
}
