import React from "react"
import { Link, StaticQuery } from "gatsby"
import { Row } from "../components/Row"
import { Column } from "../components/Column"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styled from "styled-components"
import "../styles/global.css"

const FooterWrapper = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 1rem;

  @media only screen and (min-width: 75em) {
    margin: auto;
    max-width: 85.2rem;
  }

  @media only screen and (min-width: 62em) {
    margin: auto;
    max-width: 73.2rem;
  }
`
const RowWrapper = styled.div`
  justify-content: space-between;
`
const FooterSection = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
`
const FooterHeader = styled.h4`
  margin-bottom: 0.75rem;
  font-weight: 700;
`
const FooterContent = styled.p`
  margin-bottom: 0.75rem;
  font-size: 0.875rem;
`
const CvButton = styled.button`
  max-width: 28rem;
  border-radius: 0.25rem;
  font-weight: 600;
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  background-color: #545fec;
  color: #fff;
`
const FooterListItem = styled.li`
  margin-bottom: 0.45rem;
  color: #000000cc;
`

const FooterConnectItem = styled.div`
  margin-right: 0.5rem;
  float: left;
  transition: 0.3s ease;
  opacity: 0.3;

  &:hover {
    opacity: 1;
  }
`
const FooterOutlinks = styled.div`
  margin-bottom: 0.75rem;
`
const FooterBottom = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: #5c7aff;
`
const Copyright = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const CopyText = styled.div`
  width: auto;
  text-align: left;
  font-size: 0.875rem;
  color: #fff;
  font-weight: 600;
`

export default () => {
  return (
    <footer className="footer" id="foot">
      <FooterWrapper
        data-sal="slide-up"
        data-sal-delay="1200"
        data-sal-easing="ease"
      >
        <RowWrapper>
          <Row breakpoints={[850]}>
            <Column>
              <FooterSection>
                <FooterHeader>Who?</FooterHeader>
                <FooterContent>
    I'm currently working at <OutboundLink href="https://www.linkedin.com/company/alectro-io/" target="_blank">Alectro</OutboundLink> as a Frontend Developer, helping create a platform that allows companies to understand, and take responsibility of their environmental impact.
         
                </FooterContent>
              </FooterSection>
            </Column>
            <Column>
              <FooterSection>
                <FooterHeader>The insides</FooterHeader>
                <FooterContent>
                  <ul>
                    <FooterListItem></FooterListItem>
                    <FooterListItem>
                      <Link to="/projects" className="foothighlighter">
                        Projects
                      </Link>
                    </FooterListItem>
                    <FooterListItem>
                      <Link to="/disclaimer" className="foothighlighter">
                        Disclaimer
                      </Link>
                    </FooterListItem>
                  </ul>
                </FooterContent>
              </FooterSection>
            </Column>
            <Column>
              <FooterSection>
                <FooterHeader>Recent Work</FooterHeader>
                <FooterContent>
                  <ul>
                    <StaticQuery
                      query={graphql`
                        {
                          Projects: allMdx(
                            sort: { fields: frontmatter___date, order: DESC }
                            limit: 5
                          ) {
                            edges {
                              node {
                                frontmatter {
                                  title
                                  path
                                }
                              }
                            }
                          }
                        }
                      `}
                      render={data =>
                        data.Projects.edges.map(({ node: { frontmatter } }) => (
                          <FooterListItem>
                            <Link
                              to={frontmatter.path}
                              className="foothighlighter"
                            >
                              {frontmatter.title}
                            </Link>
                          </FooterListItem>
                        ))
                      }
                    />
                  </ul>
                </FooterContent>
              </FooterSection>
            </Column>
            <Column>
              <FooterSection>
                <FooterHeader>Contact me</FooterHeader>
                <FooterOutlinks>
                  <FooterConnectItem>
                    <a href="mailto:harry@hwbuk.me">
                      <FontAwesomeIcon
                        icon={"envelope"}
                        size="2x"
                        color="#000000cc"
                      />
                    </a>
                  </FooterConnectItem>
                  <FooterConnectItem>
                    <OutboundLink
                      href="https://linkedin.com/in/harry-buchmuller"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon
                        icon={["fab", "linkedin"]}
                        size="2x"
                        color="#000000cc"
                      />
                    </OutboundLink>
                  </FooterConnectItem>
                  <FooterConnectItem>
                    <OutboundLink
                      href="https://dev.to/hwbuk"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon
                        icon={["fab", "dev"]}
                        size="2x"
                        color="#000000cc"
                      />
                    </OutboundLink>
                  </FooterConnectItem>
                </FooterOutlinks>
              </FooterSection>
            </Column>
          </Row>
        </RowWrapper>
      </FooterWrapper>
      <FooterBottom>
        <Copyright>
          <CopyText>Copyright &copy; {new Date().getFullYear() + " "}</CopyText>
          <CopyText>Made by HWBUK</CopyText>
        </Copyright>
      </FooterBottom>
    </footer>
  )
}
